<template>
  <div class="container">
    <div class="row">
        <div class="col-12 col-lg-3">
            <div class="card">
                <div class="card-body text-center">
                    <h1 class="text-center">
                        {{ users }}
                    </h1>
                    <h4 class="text-center">
                        <img :src="require('@/assets/images/users.png')" style="width: 36px" alt="">
                        الحجاج
                    </h4>
                    <div class="row">
                        <div class="col-6">
                            {{ users_1 }}
                            <br>
                            البشائر
                        </div>
                        <div class="col-6">
                            {{ users_2 }}
                            <br>
                            الميسر
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-3">
            <div class="card">
                <div class="card-body text-center">
                    <h1 class="text-center">
                        {{ busses }}
                    </h1>
                    <h4 class="text-center">
                        <img :src="require('@/assets/images/busses.png')" style="width: 36px" alt="">
                        الحافلات
                    </h4>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-3">
            <div class="card">
                <div class="card-body text-center">
                    <h1 class="text-center">
                        {{ camps }}
                    </h1>
                    <h4 class="text-center">
                        <img :src="require('@/assets/images/camps.png')" style="width: 36px" alt="">
                        الخيم
                    </h4>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-3">
            <div class="card">
                <div class="card-body text-center">
                    <h1 class="text-center">
                        {{ admins }}
                    </h1>
                    <h4 class="text-center">
                        <img :src="require('@/assets/images/admins.png')" style="width: 36px" alt="">
                        المشرفين
                    </h4>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            users: 0,
            busses: 0,
            admins: 0,
            camps: 0,
            users_1: 0,
            users_2: 0
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/web/stats', {
            jwt: g.user.jwt
        }).then(function(r){
            var users_1 = 0, users_2 = 0;
            g.busses = r.response.busses;
            g.admins = r.response.admins;
            g.camps = r.response.camps;
            g.users_1 = r.response.users_1;
            g.users_2 = r.response.users_2;
            g.users = g.users_1 + g.users_2
        })
    }
}
</script>

<style>

</style>